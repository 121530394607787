<template>
<Layout>
        <PageHeader :title="title" :items="items" />
    <div class=" container-fluid p-4">

        <!--                建立表单-->
        <b-card bg-variant="light" style="background-color:white!important;">
            <div style="position:relative;bottom: 35px;background-color:transparent;font-weight: bolder;font-size: 20px">{{$t('package')}}</div>
            <b-form action="package/hotellist">
                <b-form-group
                        label-cols-lg="12"
                        label=""
                        label-size="lg"
                        label-class="font-weight-bold pt-0"
                        class="mb-0">
                    <b-row >
                        <b-col cols="3">
                            <ap-autocomplete @selectedItem="PackageFrom" list-name="PackageFrom" url="/combined-destinations" :locale="$i18n.locale" :subTitle="'airport'" :title="'name'" :placeholder="packageInfo.departing.label"></ap-autocomplete>
                            <p style="color: red" v-show="packageFromError">{{$t('validateSelect')}}</p>

                        </b-col>
                        <b-col cols="3">
                            <ap-autocomplete @selectedItem="PackageTo" list-name="PackageTo" url="/combined-destinations" :locale="$i18n.locale" :subTitle="'airport'" :title="'name'" :placeholder="packageInfo.returning.label"></ap-autocomplete>
                            <p style="color: red" v-show="packageToError">{{$t('validateSelect')}}</p>

                        </b-col>
                        <b-col cols="3">
                            <b-form-datepicker v-model="packageInfo.departing.date" :min="minDate" :locale="$i18n.locale" class="mb-2" required placeholder="Departure Date"></b-form-datepicker>
                        </b-col>
                        <b-col cols="3">
                            <b-form-datepicker  v-model="packageInfo.returning.date" :locale="$i18n.locale" :min="new Date(new Date(this.packageInfo.departing.date).setDate(new Date(this.packageInfo.departing.date).getDate()+1))" class="mb-2" required placeholder="Return Date"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 30px">
                        <b-col md="3">
                            <b-form-select v-model="packageInfo.carbinClass" :options="carbinClassOptions" required></b-form-select>
                        </b-col>
                        <b-col md="3">
                            <b-form-select v-model="packageInfo.roomCount" :options="room_options" required></b-form-select>
                        </b-col>
                        <b-col md="3">
                            <b-form-select v-model="packageInfo.adultCount" :options="adult_options" required></b-form-select>
                        </b-col>
                        <b-col md="3">
                            <b-form-select v-model="packageInfo.childCount" :options="child_options" required></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col offset-md="5" class="mt-3">
                            <b-button variant="danger" offset-md="4" @click="packageSearch()">{{$t('searchPackage')}}</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
        </b-card>
    </div>
    <div class="c_wrap container-fluid p-4" style="margin-top: 10%" >
        <div class="c_carousel"></div>
        <carousel-3d
                :autoplay="true"
                :autoplayTimeout="3000"
                :display="5"
                :width="480"
                :height="330"
                :animationSpeed="1000"
        >

            <slide class="carousel_box" v-for="(item, index) in earthData" :key="index" :index="index">
                <div class="carousel_flashlight">
                    <img :src="item.url" alt="" style="min-width: 500px;min-height: 500px">
                </div>
            </slide>
        </carousel-3d>
    </div>

</Layout>
</template>

<script>
    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";

    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import {mapState} from 'vuex'
    import ApAutocomplete from "../../components/forms-element/autocomplete/ApAutocomplete";

    // import {validationMixin} from "vuelidate";
    // import { required, email } from 'vuelidate/lib/validators'
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import Vue from "vue";
    Vue.component('vue-phone-number-input', VuePhoneNumberInput);
    import Vuelidate from 'vuelidate'
    Vue.use(Vuelidate)


    export default {
        name: "PackageHome",
        components:{
            Layout,
            PageHeader,
            Carousel3d,
            Slide,
            ApAutocomplete
        },
        // mixins: [validationMixin],
        data(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const minDate = new Date(today);
            minDate.setDate(minDate.getDate() + 1);
            return{
                title: "Package",
                items: [
                    {
                        // #TODO
                        text: "Jebsen Travel",
                    },
                    {
                        text: "Package",
                        active: true,
                    },
                ],
                check_in_date:'',
                check_out_date:'',
                hotel_destination:'',
                earthData: [
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/17/177890/177890a_hb_r_001.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.roomstays.travel/www.miki.co.uk/live/hotel/mikiNet/image/v1.0/GB/20195/43/lr/1ex.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/17/177890/177890a_hb_r_001.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.roomstays.travel/www.miki.co.uk/live/hotel/mikiNet/image/v1.0/GB/20195/43/lr/1ex.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                ],

                packageFromModifyInput:false,
                packageToModifyInput:false,
                packageFromError:false,
                packageToError:false,
                packageFromValidate:false,
                packageToValidate:false,
                packageInfo:{
                    departing:{
                        date:'',
                        cityCode:'',
                        label:this.$t('from'),
                        countryCode:'',
                        countryName:'',
                        cityName:'',
                        cityNameCode:''
                    },
                    returning:{
                        date:'',
                        cityCode:'',
                        label:'To',
                        countryCode:'',
                        countryName:'',
                        cityName:''
                    },
                    roomCount:1,
                    adultCount:1,
                    childCount:0,
                    carbinClass:"Y",
                    cityNameCode:''

                },
                minDate:minDate,
                firstTimeInThisPage:true,

            }
        },
        methods:{
            fmtDate(obj){
                var date =  new Date(obj);
                var y = 1900+date.getYear();
                var m = "0"+(date.getMonth()+1);
                var d = "0"+date.getDate();
                return y+"-"+m.substring(m.length-2,m.length)+"-"+d.substring(d.length-2,d.length);
            },
            getDateToDAte(date) {
                var now = new Date(date);
                var YY = now.getFullYear() + '-';
                var MM = (now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1) + '-';
                var DD = (now.getDate() < 10 ? '0' + (now.getDate()) : now.getDate());
                return YY + MM + DD +"";
            },

            packageSearch(){
                if(this.packageFromValidate && this.packageToValidate){
                    sessionStorage.setItem('package',true);
                    sessionStorage.setItem('packageInfo',JSON.stringify(this.packageInfo));
                    sessionStorage.setItem('packageDepartureLabel',this.packageInfo.departing.label)
                    sessionStorage.setItem('packageDestinationLabel',this.packageInfo.returning.label)
                    let packageDepartureDate = this.fmtDate(this.packageInfo.departing.date);
                    let packageDestinationDate = this.fmtDate(this.packageInfo.returning.date);
                    let adultCount = this.packageInfo.adultCount;
                    let childCount = this.packageInfo.childCount;
                    let FromcityCode = this.packageInfo.departing.cityNameCode;
                    let TocityCode = this.packageInfo.returning.cityNameCode;
                    let FromcountryCode = this.packageInfo.departing.countryCode;
                    let TocountryCode = this.packageInfo.returning.countryCode;
                    let carbinClass = this.packageInfo.carbinClass;
                    let departureDate = this.packageInfo.departing.date
                    this.$router.push({
                        path:'/package/hotellist',
                        query:{
                            flightsCount:1,
                            packageDepartureDate: packageDepartureDate,
                            packageDestinationDate: packageDestinationDate,
                            adultCount:adultCount,
                            childCount:childCount,
                            FromcityCode:FromcityCode,
                            TocityCode:TocityCode,
                            FromcountryCode:FromcountryCode,
                            TocountryCode:TocountryCode,
                            carbinClass:carbinClass,
                            departureDate:departureDate,
                            roomCount:this.packageInfo.roomCount,
                            fromCityNameCode:this.packageInfo.departing.cityCode,
                            toCityNameCode:this.packageInfo.returning.cityCode,
                        }
                    });
                }
                else if((!this.packageFromValidate && !this.packageFromModifyInput) && (!this.packageToValidate && !this.packageToModifyInput) && !this.firstTimeInThisPage ){
                    sessionStorage.setItem('package',true);
                    sessionStorage.setItem('packageInfo',JSON.stringify(this.packageInfo));
                    sessionStorage.setItem('packageDepartureLabel',this.packageInfo.departing.label)
                    sessionStorage.setItem('packageDestinationLabel',this.packageInfo.returning.label)
                    let packageDepartureDate = this.fmtDate(this.packageInfo.departing.date);
                    let packageDestinationDate = this.fmtDate(this.packageInfo.returning.date);
                    let adultCount = this.packageInfo.adultCount;
                    let childCount = this.packageInfo.childCount;
                    let FromcityCode = this.packageInfo.departing.cityNameCode;
                    let TocityCode = this.packageInfo.returning.cityNameCode;
                    let FromcountryCode = this.packageInfo.departing.countryCode;
                    let TocountryCode = this.packageInfo.returning.countryCode;
                    let carbinClass = this.packageInfo.carbinClass;
                    let departureDate = this.packageInfo.departing.date
                    this.$router.push({
                        path:'/package/hotellist',
                        query:{
                            flightsCount:1,
                            packageDepartureDate: packageDepartureDate,
                            packageDestinationDate: packageDestinationDate,
                            adultCount:adultCount,
                            childCount:childCount,
                            FromcityCode:FromcityCode,
                            TocityCode:TocityCode,
                            FromcountryCode:FromcountryCode,
                            TocountryCode:TocountryCode,
                            carbinClass:carbinClass,
                            departureDate:departureDate,
                            roomCount:this.packageInfo.roomCount,
                            fromCityNameCode:this.packageInfo.departing.cityCode,
                            toCityNameCode:this.packageInfo.returning.cityCode,
                        }
                    });
                }
                else if((this.packageFromValidate && this.packageFromModifyInput) && (!this.packageToValidate && !this.packageToModifyInput)  && !this.firstTimeInThisPage && this.packageInfo.departing.cityCode!=undefined && this.packageInfo.returning.cityCode!=undefined){
                    sessionStorage.setItem('package',true);
                    sessionStorage.setItem('packageInfo',JSON.stringify(this.packageInfo));
                    sessionStorage.setItem('packageDepartureLabel',this.packageInfo.departing.label)
                    sessionStorage.setItem('packageDestinationLabel',this.packageInfo.returning.label)
                    let packageDepartureDate = this.fmtDate(this.packageInfo.departing.date);
                    let packageDestinationDate = this.fmtDate(this.packageInfo.returning.date);
                    let adultCount = this.packageInfo.adultCount;
                    let childCount = this.packageInfo.childCount;
                    let FromcityCode = this.packageInfo.departing.cityNameCode;
                    let TocityCode = this.packageInfo.returning.cityNameCode;
                    let FromcountryCode = this.packageInfo.departing.countryCode;
                    let TocountryCode = this.packageInfo.returning.countryCode;
                    let carbinClass = this.packageInfo.carbinClass;
                    let departureDate = this.packageInfo.departing.date
                    this.$router.push({
                        path:'/package/hotellist',
                        query:{
                            flightsCount:1,
                            packageDepartureDate: packageDepartureDate,
                            packageDestinationDate: packageDestinationDate,
                            adultCount:adultCount,
                            childCount:childCount,
                            FromcityCode:FromcityCode,
                            TocityCode:TocityCode,
                            FromcountryCode:FromcountryCode,
                            TocountryCode:TocountryCode,
                            carbinClass:carbinClass,
                            departureDate:departureDate,
                            roomCount:this.packageInfo.roomCount,
                            fromCityNameCode:this.packageInfo.departing.cityCode,
                            toCityNameCode:this.packageInfo.returning.cityCode,
                        }
                    });
                }
                else if((!this.packageFromValidate && !this.packageFromModifyInput) && (this.packageToValidate && this.packageToModifyInput)  && !this.firstTimeInThisPage && this.packageInfo.departing.cityCode!=undefined && this.packageInfo.returning.cityCode!=undefined){
                    sessionStorage.setItem('package',true);
                    sessionStorage.setItem('packageInfo',JSON.stringify(this.packageInfo));
                    sessionStorage.setItem('packageDepartureLabel',this.packageInfo.departing.label)
                    sessionStorage.setItem('packageDestinationLabel',this.packageInfo.returning.label)
                    let packageDepartureDate = this.fmtDate(this.packageInfo.departing.date);
                    let packageDestinationDate = this.fmtDate(this.packageInfo.returning.date);
                    let adultCount = this.packageInfo.adultCount;
                    let childCount = this.packageInfo.childCount;
                    let FromcityCode = this.packageInfo.departing.cityNameCode;
                    let TocityCode = this.packageInfo.returning.cityNameCode;
                    let FromcountryCode = this.packageInfo.departing.countryCode;
                    let TocountryCode = this.packageInfo.returning.countryCode;
                    let carbinClass = this.packageInfo.carbinClass;
                    let departureDate = this.packageInfo.departing.date
                    this.$router.push({
                        path:'/package/hotellist',
                        query:{
                            flightsCount:1,
                            packageDepartureDate: packageDepartureDate,
                            packageDestinationDate: packageDestinationDate,
                            adultCount:adultCount,
                            childCount:childCount,
                            fromCityNameCode:this.packageInfo.departing.cityCode,
                            toCityNameCode:this.packageInfo.returning.cityCode,
                            FromcityCode:FromcityCode,
                            TocityCode:TocityCode,
                            FromcountryCode:FromcountryCode,
                            TocountryCode:TocountryCode,
                            carbinClass:carbinClass,
                            departureDate:departureDate,
                            roomCount:this.packageInfo.roomCount
                        }
                    });
                }
                else{
                    if(!this.packageFromValidate || this.packageInfo.departing.cityCode==undefined){
                        this.packageFromError = true
                    }
                    if(!this.packageToValidate || this.packageInfo.returning.cityCode==undefined){
                        this.packageToError = true
                    }
                }
            },
            PackageFrom(item){
                this.packageFromModifyInput = true;
                this.packageFromError = false;
                this.packageFromValidate = true;
                this.packageInfo.departing.label = item.name
                this.packageInfo.departing.cityCode = item.giataCityId;
                this.packageInfo.departing.countryCode = item.countryCode;
                this.packageInfo.departing.countryName = item.countryName;
                this.packageInfo.departing.cityName = item.cityName;
                this.packageInfo.departing.cityNameCode = item.cityCode;
            },
            PackageTo(item){
                this.packageToModifyInput = true;
                this.packageToError = false;
                this.packageToValidate = true;
                this.packageInfo.returning.label = item.name
                this.packageInfo.returning.cityCode = item.giataCityId;
                this.packageInfo.returning.countryCode = item.countryCode;
                this.packageInfo.returning.cityNameCode = item.cityCode;
            },
        },
        computed:{
            ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
            adult_options:function () {
                return [
                    {
                        value:1,text:this.$t('adult_1')
                    },
                    {
                        value:2,text:this.$t('adult_2')
                    },
                    {
                        value:3,text:this.$t('adult_3')
                    },
                    {
                        value:4,text:this.$t('adult_4')
                    }
                ]
            },
            child_options:function () {
                return [
                    {
                        value:0,text:this.$t('child_0')
                    },
                    {
                        value:1,text:this.$t('child_1')
                    },
                    {
                        value:2,text:this.$t('child_2')
                    },
                    {
                        value:3,text:this.$t('child_3')
                    },
                    {
                        value:4,text:this.$t('child_4')
                    }
                ]
            },
            room_options:function () {
                return [
                    {
                        value:1,text:this.$t('room_1')
                    },
                    {
                        value:2,text:this.$t('room_2')
                    },
                    {
                        value:3,text:this.$t('room_3')
                    },
                    {
                        value:4,text:this.$t('room_4')
                    }
                ]
            },
            carbinClassOptions:function () {
                return [
                    {
                        value:"Y",text:this.$t('Economy')
                    },
                    {
                        value:"W",text:this.$t('premiumEconomy')
                    },
                    {
                        value:"F",text:this.$t('firstCarbin')
                    },
                    {
                        value:"C",text:this.$t('business')
                    }
                ]
            },
            // packageInfo:function () {
            //     return {
            //         departing:{
            //             date:'',
            //             cityCode:'',
            //             label:this.$t('from'),
            //             countryCode:'',
            //             countryName:'',
            //             cityName:'',
            //             cityNameCode:''
            //         },
            //         returning:{
            //             date:'',
            //             cityCode:'',
            //             label:this.$t('to'),
            //             countryCode:'',
            //             countryName:'',
            //             cityName:''
            //         },
            //         roomCount:1,
            //         adultCount:1,
            //         childCount:0,
            //         carbinClass:"Y",
            //         cityNameCode:''
            //
            //     }
            // }
            // 'packageInfo.departing.label':function(){
            //     return this.$t('from')
            // },
            // 'packageInfo.returning.label':function(){
            //     return this.$t('to')
            // }
        },
        created(){
            if (sessionStorage.getItem('package')){
                this.packageInfo = JSON.parse(sessionStorage.getItem('packageInfo'));
                this.firstTimeInThisPage = false
            }
            else{
                this.packageInfo.departing.date = this.fmtDate(this.minDate)
            }
        },
        mounted() {
        },
        watch:{
            'packageInfo.departing.date':function() {
                this.$nextTick(function(){
                    this.packageInfo.returning.date = new Date(new Date(this.packageInfo.departing.date).setDate(new Date(this.packageInfo.departing.date).getDate()+1))
                })
            },

        }

    }
</script>

<style scoped>

</style>